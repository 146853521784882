import Header from "../../components/header/Header";
import { Link, useNavigate } from "react-router-dom";
import "./adminHome.css";
import { useEffect, useState } from "react";
import {
  API_AUTH_USERNAME,
  API_AUTH_PASSWORD,
  API_BASE_URL,
} from "../../Constant/Global";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Doughnut } from "react-chartjs-2";
import "../DCAFVerification/DCAFVerification.css";

const ImportCustomerIdData = () => {
  const navigate = useNavigate();
  const [city, setCity] = useState("");
  const [base64Data, setBase64Data] = useState("");
  const [uploadFileData, setUploadFileData] = useState("");
  const [downloadSampleFileUrl, setDownloadSampleFileUrl] = useState("");
  const userDetails = sessionStorage.getItem("info");
  const userInfo = JSON.parse(userDetails);
  const [stage, setStage] = useState(1);
  const [totalEntries, setTotalEntries] = useState(500); // Example total entries
  const [completed, setCompleted] = useState("0");
  const [underProcess, setUnderProcess] = useState("0");

  // download sample file

  const handleDownloadSampleFile = async () => {
    try {
      const finalData = {};
      const response = await axios.post(
        API_BASE_URL + "extract/excel_sample_file",
        finalData,
        {
          auth: {
            username: API_AUTH_USERNAME,
            password: API_AUTH_PASSWORD,
          },
        }
      );

      // console.log("download sample file response", response.data.data);
      setDownloadSampleFileUrl(response.data.data);
    } catch (error) {
      if (error.response) {
        console.log(error.response.data.data);
        console.log(error.response.status);
      }
    }
  };

  useEffect(() => {
    handleDownloadSampleFile();
  }, []);

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();

      reader.onload = (e) => {
        const base64Content = e.target.result.split(",")[1];
        setBase64Data(base64Content);
      };

      reader.readAsDataURL(file);
    }
  };

  // back  navigate page

  const handleBack = () => {
    navigate(-1);
  };
  const handleImportNewBack = () => {
    // navigate(-1);
    setStage("0 Stage");
  };

  // upload file api

  const handleUploadFile = async (e) => {
    e.preventDefault();
    try {
      if (base64Data) {
        const data = {
          user_id: userInfo.id,
          excel_file: base64Data,
          city: city,
        };
        const response = await axios.post(
          API_BASE_URL + "extract/upload_bulk_customer_ids",
          data,
          {
            auth: {
              username: API_AUTH_USERNAME,
              password: API_AUTH_PASSWORD,
            },
          }
        );
        if (response.data.status) {
          setUploadFileData(response.data);
          handleRefresh();
          toast.success(response.data.message);
        } else {
          toast.error(response.data.message);
        }
      } else {
        console.log("Please select an Excel file before uploading.");
      }
    } catch (error) {
      console.log("error occurred in add imp places", error);
    }
  };

  // export pdf

  // Function to simulate starting verification
  const handleExport = async () => {
    try {
      const response = await axios.post(
        API_BASE_URL + "extract/download_extract_data",
        {
          auth: {
            username: API_AUTH_USERNAME,
            password: API_AUTH_PASSWORD,
          },
        }
      );
      console.log("export data", response.data.data);
      if (response.data.status) {
        toast.success(response.data.message);
        // Open the link in a new tab
        window.open(response.data.data, "_blank");
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      if (error.response) {
        console.log(error.response.data.data);
        console.log(error.response.status);
      }
    }
  };

  // new code

  const fetchVerificationStatus = async () => {
    try {
      const response = await axios.post(
        API_BASE_URL + "extract/extract_tool_info",
        {
          auth: {
            username: API_AUTH_USERNAME,
            password: API_AUTH_PASSWORD,
          },
        }
      );
      console.log("auto verification customers info", response.data.data);
      if (response.data.status) {
        setStage(response.data.data.stage);
        setTotalEntries(response.data.data.total_customer);
        setCompleted(response.data.data.completed_customer);
        setUnderProcess(response.data.data.pending_customer);
        if (response.data.data.status === "Completed") {
          setStage("3rd Stage"); // Move to 3rd stage when verification is successful
        }
      }
    } catch (error) {
      if (error.response) {
        console.log(error.response.data.data);
        console.log(error.response.status);
      }
    }
  };

  useEffect(() => {
    fetchVerificationStatus();
  }, []);

  const handleRefresh = async () => {
    await fetchVerificationStatus();
  };

  // Function to simulate starting verification
  const startVerification = async () => {
    // setStage("2nd Stage");
    // fetchVerificationStatus();
    try {
      const userDetails = sessionStorage.getItem("info");
      const userInfo = JSON.parse(userDetails);
      const finalData = {
        user_id: userInfo.id,
      };
      const response = await axios.post(
        API_BASE_URL + "extract/start_auto_verification",
        finalData,
        {
          auth: {
            username: API_AUTH_USERNAME,
            password: API_AUTH_PASSWORD,
          },
        }
      );
      console.log("auto verification customers info", response.data.data);
      if (response.data.status) {
        toast.success(response.data.message);
        setTimeout(() => {
          window.location.reload();
        }, 2000);
      } else {
        toast.error(response.data.message);
        setTimeout(() => {
          window.location.reload();
        }, 2000);
      }
    } catch (error) {
      if (error.response) {
        console.log(error.response.data.data);
        console.log(error.response.status);
      }
    }
  };

  // Trigger verification status check in intervals for 2nd stage
  useEffect(() => {
    if (stage === "2nd Stage") {
      const interval = setInterval(fetchVerificationStatus, 5000); // Poll every 5 seconds
      //   setStage(3);
      return () => clearInterval(interval); // Clean up the interval on unmount
    }
  }, [stage]);

  // Data for the chart with data labels
  const data = {
    labels: ["Completed", "Under Process"],
    datasets: [
      {
        // label: `${Extract Tool} Verification`,
        data: [completed, underProcess],
        backgroundColor: ["#4CAF50", "#FFC107"],
        hoverOffset: 4,
      },
    ],
  };

  // Options for the chart, including data labels to show counts
  const options = {
    plugins: {
      datalabels: {
        color: "#000", // Color of the data labels (white for better contrast)
        font: {
          size: 15, // Increase font size
          weight: "bold",
        },
        formatter: (value) => `${value}`,
        anchor: "center", // Align labels to the center of the segments
        align: "center", // Ensure the labels are displayed inside the segments
      },
      legend: {
        display: true,
        position: "top",
      },
      tooltip: {
        enabled: true,
      },
    },
    // cutout: "70%", // Adjust the cutout size to create a bigger center space
    responsive: true,
    maintainAspectRatio: false, // Disable aspect ratio to control height/width
  };

  return (
    <>
      <Header />
      {stage === "0 Stage" && (
        <section className="adminHomeMain">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="downloadSamplePdfFileBox">
                  <h5>Import Customer Id Data </h5>
                  <Link
                    to={downloadSampleFileUrl}
                    className="btn"
                    target="_blank"
                    download
                  >
                    Download Sample File
                  </Link>
                </div>
              </div>
              <div className="col-lg-12 ">
                <div className="pdfUploadMain">
                  <form
                    id="importForm"
                    name="importForm"
                    className="pdfUploadForm"
                  >
                    <input
                      type="file"
                      accept=".xls, .xlsx"
                      className="form-control"
                      onChange={handleFileChange}
                    />
                    <button
                      className="btn"
                      onClick={handleUploadFile}
                      disabled={!base64Data}
                    >
                      Upload
                    </button>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </section>
      )}

      <section className="dcaf-container">
        {stage === "1st Stage" && (
          <div className="stage-box">
            <h2>Extract Tool Verification</h2>
            <p>
              Total Entries: <span>{totalEntries} (Customers)</span>
            </p>
            {/* {location.pathname !== "/verification/Pune/EKYC" && (
              <div className="checkbox-verify-id">
                <label>
                  <input
                    type="checkbox"
                    onChange={handleCheckboxChange}
                    checked={verifyId === "Y"}
                  />
                  Verify Customer Id proof <br />
                  {verifyId === "Y" && (
                    <span style={{ color: "red" }}>
                      It will take some time for verification to complete
                    </span>
                  )}
                </label>
              </div>
            )} */}
            <button
              disabled={underProcess === "0" && "disabled"}
              className="start-btn"
              onClick={startVerification}
            >
              Start Verification
            </button>
          </div>
        )}

        {stage === "2nd Stage" && (
          <div className="stage-box">
            <h2>Extract Tool in Progress</h2>
            {data.datasets[0].data.some((val) => val > 0) ? (
              <div className="chart-wrapper">
                <Doughnut data={data} options={options} />
              </div>
            ) : (
              <div className="chart-wrapper">
                <p>No data available</p>
              </div>
            )}
            <div className="details">
              <p>
                Total Entries: <span>{totalEntries}</span>
              </p>
              <p>
                Completed: <span>{completed}</span>
              </p>
              <p>
                Under Process: <span>{underProcess}</span>
              </p>
              <p className="status-message">
                This Process will take some time, you can close this window and
                check status letter
              </p>
            </div>
          </div>
        )}

        {stage === "3rd Stage" && (
          <div className="stage-box">
            <h4 className="stage-success-title">
              Extract Tool Process Completed
            </h4>
            {/* <h6>All forms Audit Done</h6> */}
            <button className="backBtn btn" onClick={handleBack}>
              Back
            </button>
            <button className="backBtn ms-3 btn" onClick={handleImportNewBack}>
              Import New Data
            </button>
            <button
              style={{ backgroundColor: "gray" }}
              className="backBtn btn ms-3"
              onClick={handleExport}
            >
              Export
            </button>
          </div>
        )}
      </section>
      <ToastContainer
        position={"top-right"}
        autoClose={2000}
        pauseOnHover={false}
        closeOnClick={true}
      />
    </>
  );
};

export default ImportCustomerIdData;
