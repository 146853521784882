import "./header.css";
import { useNavigate, useParams } from "react-router-dom";

const Header = ({ handleOpenedCustomer }) => {
  const userDetails = sessionStorage.getItem("info");
  const userInfo = JSON.parse(userDetails);
  // console.log("userInfo", userInfo);
  const adminDetails = sessionStorage.getItem("adminInfo");
  const adminInfo = JSON.parse(adminDetails);

  const qcDetails = sessionStorage.getItem("qcInfo");
  const qcInfoData = JSON.parse(qcDetails);

  const navigate = useNavigate();
  const { simType, name } = useParams();
  const { id } = useParams();
  const handleLogOut = () => {
    if (window.location.pathname === "/") {
      sessionStorage.removeItem("info");
      navigate("/login");
    } else if (window.location.pathname === `/home/${name}`) {
      sessionStorage.removeItem("info");
      navigate("/login");
    } else if (window.location.pathname === `/home/extract-tool`) {
      sessionStorage.removeItem("info");
      navigate("/login");
    }  else if (window.location.pathname === `/image-audit/${simType}`) {
      handleOpenedCustomer();
      sessionStorage.removeItem("info");
      navigate("/login");
    } else if (
      window.location.pathname === "/admin/home" ||
      window.location.pathname === "/admin/export-file" ||
      window.location.pathname === "/admin/manage-auditor" ||
      window.location.pathname === "/admin/add-auditor" ||
      window.location.pathname === `/admin/edit-auditor/${id}` ||
      window.location.pathname === "/admin/manage-remark" ||
      window.location.pathname === "/admin/add-remark" ||
      window.location.pathname === `/admin/edit-remark/${id}` ||
      window.location.pathname === "/admin/manage-qc" ||
      window.location.pathname === "/admin/add-qc" ||
      window.location.pathname === `/admin/edit-qc/${id}` ||
      window.location.pathname === `/verification/Pune/DCAF` || 
      window.location.pathname === `/verification/ROM/DCAF`
    ) {
      sessionStorage.removeItem("adminInfo");
      navigate("/admin/login");
    } else if (
      window.location.pathname === "/qc-panel" ||
      window.location.pathname === `/qc-panel/${id}`
    ) {
      sessionStorage.removeItem("qcInfo");
      navigate("/qc-panel/login");
    }
  };

  return (
    <header className="header-main">
      <div className="container-fluid">
        <div className="row">
          <div className="col-lg-4 col-md-4 ps-0 ">
            <div className="header-left-box">
              <p>image audit system software </p>
            </div>
          </div>
          <div className="col-lg-8 col-md-8">
            <div className="header-right-box">
              <p>Dynamic Information Technology</p>
              {sessionStorage.getItem("info") !== null && (
                <p className="userNameHeader">Welcome, {userInfo.name}</p>
              )}
              {sessionStorage.getItem("adminInfo") !== null && (
                <p className="userNameHeader">Welcome, {adminInfo.name}</p>
              )}
              {sessionStorage.getItem("qcInfo") !== null && (
                <p className="userNameHeader">Welcome, {qcInfoData.name}</p>
              )}
              {(sessionStorage.getItem("info") !== null ||
                sessionStorage.getItem("adminInfo") !== null ||
                sessionStorage.getItem("qcInfo") !== null) && (
                <div className="logOutHeaderBox" onClick={handleLogOut}>
                  <svg
                    width={24}
                    height={24}
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <mask
                      id="mask0_13_482"
                      style={{ maskType: "alpha" }}
                      maskUnits="userSpaceOnUse"
                      x={0}
                      y={0}
                      width={24}
                      height={24}
                    >
                      <rect width={24} height={24} fill="white" />
                    </mask>
                    <g mask="url(#mask0_13_482)">
                      <path
                        d="M12 12C11.6319 12 11.319 11.8712 11.0614 11.6136C10.8038 11.356 10.675 11.0431 10.675 10.675V2.84455C10.675 2.47643 10.8038 2.16356 11.0614 1.90595C11.319 1.64835 11.6319 1.51955 12 1.51955C12.3681 1.51955 12.681 1.64835 12.9386 1.90595C13.1962 2.16356 13.325 2.47643 13.325 2.84455V10.675C13.325 11.0431 13.1962 11.356 12.9386 11.6136C12.681 11.8712 12.3681 12 12 12ZM12 21.4804C10.684 21.4804 9.45196 21.2312 8.30378 20.7326C7.15558 20.2341 6.15376 19.5571 5.29833 18.7016C4.4429 17.8462 3.76591 16.8444 3.26736 15.6962C2.76881 14.548 2.51953 13.3159 2.51953 12C2.51953 10.9927 2.67206 10.01 2.97713 9.05162C3.2822 8.09329 3.73726 7.20941 4.34233 6.4C4.57278 6.08188 4.88148 5.91756 5.26843 5.90705C5.6554 5.89655 5.9949 6.03261 6.28693 6.31522C6.53621 6.55507 6.65959 6.85308 6.65706 7.20925C6.65451 7.56541 6.55106 7.89785 6.34673 8.20655C5.96266 8.76886 5.67046 9.37067 5.47011 10.012C5.26974 10.6533 5.16956 11.3159 5.16956 12C5.16956 13.9029 5.83224 15.517 7.15761 16.8424C8.48296 18.1677 10.0971 18.8304 12 18.8304C13.9029 18.8304 15.517 18.1677 16.8424 16.8424C18.1677 15.517 18.8304 13.9029 18.8304 12C18.8304 11.3087 18.732 10.6382 18.5353 9.9886C18.3386 9.33896 18.0362 8.73299 17.6282 8.17067C17.4239 7.86922 17.3181 7.54458 17.3109 7.19675C17.3036 6.84891 17.4246 6.55507 17.6739 6.31522C17.9587 6.03986 18.2946 5.90506 18.6815 5.91085C19.0685 5.91665 19.3819 6.07499 19.6218 6.38587C20.2435 7.19529 20.7087 8.07736 21.0174 9.03207C21.3261 9.98677 21.4804 10.9761 21.4804 12C21.4804 13.3159 21.2312 14.548 20.7326 15.6962C20.2341 16.8444 19.5571 17.8462 18.7016 18.7016C17.8462 19.5571 16.8444 20.2341 15.6962 20.7326C14.548 21.2312 13.3159 21.4804 12 21.4804Z"
                        fill="white"
                      />
                    </g>
                  </svg>
                  <span>Log Out</span>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;
